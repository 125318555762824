import type {
  ProductSearchFacets,
  ProductSearchResponseDTO,
  SearchBrandFacetDTO,
  SearchOptionFacetDTO,
  StoreSearchProductFilterDTO
} from 'ecosystem'
import lodashSortBy from 'lodash/sortBy'
import { baseSplitApi } from '../../store'

export interface GetSearchProductEndpointPayload {
  filter: StoreSearchProductFilterDTO
  size: number
  page: number
}

const sortFacets = (arr: SearchBrandFacetDTO[]) => {
  return arr.length > 1 ? lodashSortBy(arr, 'name') : []
}

const sortOptionsFacets = (arr: SearchOptionFacetDTO[]) => {
  const filtered = arr.filter((option) => option.values.length > 1)
  const sortedArr = lodashSortBy(filtered, 'name')

  return sortedArr.map((option) => ({
    ...option,
    values: lodashSortBy(option.values, 'value')
  }))
}

type GetSearchProductEndpointResponse = Omit<ProductSearchResponseDTO, 'facets'> & {
  facets: Partial<ProductSearchFacets>
}

export const getSearchProductsEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSearchProducts: builder.query<ProductSearchResponseDTO, GetSearchProductEndpointPayload>({
      query: ({ filter, size, page }) => ({
        url: `products/search-products`,
        method: 'POST',
        body: filter,
        params: {
          size,
          page
        }
      }),
      transformResponse: ({ facets, ...response }: GetSearchProductEndpointResponse) => {
        return {
          facets: {
            ...facets,
            brands: facets.brands?.length ? sortFacets(facets.brands) : [],
            categories: facets.categories?.length ? sortFacets(facets.categories) : [],
            options: facets.options?.length ? sortOptionsFacets(facets.options) : [],
            prices: facets.prices || []
          },
          ...response
        } as ProductSearchResponseDTO
      }
    })
  })
})

export const { useLazyGetSearchProductsQuery } = getSearchProductsEndpoint
