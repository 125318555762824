import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CategoryState } from 'ecosystem/categories'
import type { CategoryDTO } from 'ecosystem/grand-connect'

const initialState: CategoryState = {
  category: null,
  categories: [],
  menuCategories: [],
  menuCategoryId: null,
  navigateToCategoryId: null
}

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setSelectedCategories: (state, { payload: categories }: PayloadAction<CategoryDTO[]>) => {
      state.categories = categories
      return state
    },

    setNavigateToCategoryId: (state, { payload }: PayloadAction<{ categoryId: string | null }>) => {
      state.navigateToCategoryId = payload.categoryId
      return state
    },

    setSelectedMenuCategories: (state, { payload }: PayloadAction<CategoryDTO[]>) => {
      state.menuCategories = payload
      return state
    },

    setSelectedMenuCategoryId: (state, { payload }: PayloadAction<string | null>) => {
      state.menuCategoryId = payload
      return state
    },

    setSelectedMenuCategory: (state, { payload }: PayloadAction<string | null>) => {
      state.category = payload
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const {
  setSelectedCategories,
  setNavigateToCategoryId,
  setSelectedMenuCategories,
  setSelectedMenuCategoryId,
  setSelectedMenuCategory
} = slice.actions

// available selectors - add more as needed
export const selectFeaturedCategories = (state: { category: CategoryState }) =>
  state.category.categories.slice(0, 5)
export const selectCategories = (state: { category: CategoryState }) => state.category.categories
export const selectedMenuCategories = (state: { category: CategoryState }) =>
  state.category.menuCategories
export const selectedMenuCategoryId = (state: { category: CategoryState }) =>
  state.category.menuCategoryId
export const selectedMenuCategory = (state: { category: CategoryState }) => state.category.category
export const selectedNavigateToCategory = (state: { category: CategoryState }) =>
  state.category.navigateToCategoryId

// exports slice reducer
export default slice.reducer
