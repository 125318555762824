import type { CartState } from 'ecosystem'

const CART_STORAGE_ITEM_ID = 'cartSliceState'

type CartSavedType = CartState & {
  expiredDate: number
}

const oneHour = 3600 * 1000

export const updateCartSliceInStorage = (cart: CartState) => {
  try {
    const result: CartSavedType = {
      expiredDate: new Date().getTime() + oneHour,
      ...cart
    }

    window?.localStorage.setItem(CART_STORAGE_ITEM_ID, btoa(JSON.stringify(result)))
  } catch (e) {
    void 0
  }
}

export const getCartSliceFromStorage = (): CartState | null => {
  try {
    const result = window?.localStorage.getItem(CART_STORAGE_ITEM_ID)

    if (result === null) {
      return null
    }

    const parsedResult: CartSavedType | null = JSON.parse(atob(result))

    if (parsedResult === null) {
      return null
    }

    const { expiredDate, ...cartState } = parsedResult

    if (Date.now() > expiredDate) {
      deleteCartSliceFromStorage()

      return null
    }

    return cartState
  } catch (e) {
    return null
  }
}

export const deleteCartSliceFromStorage = () => {
  window?.localStorage.removeItem(CART_STORAGE_ITEM_ID)
}
