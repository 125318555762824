import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CartItem, CartState, DiscountDTO, Language } from 'ecosystem'
import { pushOrUpdate } from 'shared-utils'
import { v4 as uuidv4 } from 'uuid'
import {
  deleteCartSliceFromStorage,
  getCartSliceFromStorage,
  updateCartSliceInStorage
} from './utils'

const initialState: CartState = {
  cartLocale: null,
  cartItems: [],
  cartTotal: 0,
  cartDiscountCode: null,
  cartStateUuid: null
}

export const sumCart = (cartItems: CartItem[]) => {
  let total = 0
  for (let i = 0, _len = cartItems.length; i < _len; i++) {
    const value = cartItems[i].price
    if (value) {
      total += value * parseInt(cartItems[i].qty || '')
    }
  }
  return total
}

export const applyDiscountToCart = (cartItems: CartItem[], discountCode: DiscountDTO) => {
  const discountRate = discountCode.value / 100
  return cartItems.map((cartItem) => {
    const isDiscountCandidate =
      (cartItem.brand?.id && discountCode.brandIds?.includes(cartItem.brand.id)) ||
      discountCode.categoryIds?.some((c) => cartItem.categoryIds?.includes(c)) ||
      discountCode.tagIds?.some((t) => cartItem.tags?.map((ptag) => ptag.id).includes(t))

    const isOriginalPriceLower = cartItem.price > cartItem.originalPrice

    if (isDiscountCandidate) {
      if (!discountCode.campaign && !isOriginalPriceLower) {
        const itemDiscount = cartItem.originalPrice * discountRate

        return {
          ...cartItem,
          price: cartItem.originalPrice - itemDiscount
        }
      }
      const itemDiscount = cartItem.price * discountRate

      return {
        ...cartItem,
        price: cartItem.price - itemDiscount
      }
    }
    return cartItem
  })
}

const slice = createSlice({
  name: 'cart',
  initialState: () => {
    return getCartSliceFromStorage() ?? initialState
  },
  reducers: {
    setCartLocale: (state, { payload: cartLocale }: PayloadAction<Language>) => {
      state.cartLocale = cartLocale
      return state
    },

    addToCart: (state, { payload: cartItem }: PayloadAction<CartItem>) => {
      const currentCartItems = [...state.cartItems]

      if (!cartItem.price) {
        return state
      }

      pushOrUpdate(
        currentCartItems,
        cartItem,
        (e: any) => {
          return e.id === cartItem.id
        },
        'id'
      )

      state.cartItems = [...currentCartItems]
      state.cartTotal = sumCart(state.cartItems)
      state.cartStateUuid = uuidv4()

      updateCartSliceInStorage(state)
      return state
    },

    removeFromCart: (state, { payload: cartItem }: PayloadAction<CartItem>) => {
      const currentCartItems = [...state.cartItems]
      state.cartItems = [...currentCartItems.filter((e) => e.id !== cartItem.id)]
      state.cartTotal = sumCart(state.cartItems)
      state.cartStateUuid = uuidv4()

      updateCartSliceInStorage(state)
      return state
    },

    applyDiscountCode: (state, { payload: discountCode }: PayloadAction<DiscountDTO>) => {
      state.cartDiscountCode = discountCode
      state.cartStateUuid = uuidv4()

      updateCartSliceInStorage(state)
      return state
    },

    cleanCart: () => {
      deleteCartSliceFromStorage()

      return initialState
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setCartLocale, addToCart, removeFromCart, applyDiscountCode, cleanCart } =
  slice.actions

// available selectors - add more as needed
export const selectedCart = (state: { cart: CartState }) => state.cart
export const selectedCartItems = (state: { cart: CartState }) => state.cart.cartItems
export const selectedCartDiscount = (state: { cart: CartState }) => state.cart.cartDiscountCode
// exports slice reducer
export default slice.reducer
