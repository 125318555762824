import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { WindowState } from './types'

const initialState: WindowState = {
  isWindowOnTop: true,
  prevLocation: '',
  currentLocation: ''
}

const slice = createSlice({
  name: 'window',
  initialState,
  reducers: {
    setIsWindowOnTop: (state, { payload: isWindowOnTop }: PayloadAction<boolean>) => {
      state.isWindowOnTop = isWindowOnTop
      return state
    },
    setLocation: (
      state,
      { payload: { prev, current } }: PayloadAction<{ prev: string; current: string }>
    ) => {
      state.prevLocation = prev
      state.currentLocation = current
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { setIsWindowOnTop, setLocation } = slice.actions

// available selectors - add more as needed
export const selectWindowState = (state: { window: WindowState }) => state.window
export const selectIsWindowOnTop = (state: { window: WindowState }) => state.window.isWindowOnTop
export const selectPrevLocation = (state: { window: WindowState }) => state.window.prevLocation
export const selectCurrentLocation = (state: { window: WindowState }) =>
  state.window.currentLocation

// exports slice reducer
export default slice.reducer
