/* eslint-disable
 no-console,camelcase,@typescript-eslint/non-nullable-type-assertion-style  -- Disabled for this file since apply special rules to GA*/
import type { CartItem, CheckoutProduct } from 'ecosystem'
import { Currency } from 'ecosystem'
import { type GaPurchaseOrder, type GaSearchItem } from './types'

declare global {
  interface Window {
    gtag?: (...args: any[]) => void
  }
}

const useGoogleAnalytics = function () {
  // ******************* GOOGLE ANALYTICS API - COMMON *****************

  const pageView = (url: string) => {
    try {
      window.gtag?.('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4, {
        page_path: url
      })
    } catch (e) {
      console.warn('GA - could not update page path')
    }
  }

  const selectContent = (content: { content_type: string; item_id: string }) => {
    try {
      window.gtag?.('event', 'select_content', content)
    } catch (e) {
      console.warn('GA - could not update select content')
    }
  }

  const contactFormSubmit = (page_path: string) => {
    if (typeof window !== 'undefined') {
      try {
        window.gtag?.('event', 'contact_form_submit', { page_path })
      } catch (e) {
        console.warn('GA - could not update contact form submit')
      }
    }
  }

  // ******************* HOOK METHODS ***********************
  const itemFactory = (items: CartItem[]) => {
    return items.map((item, index) => ({
      affiliation: 'Online Store',
      item_id: item.id,
      item_name: item.name,
      item_brand: item?.brand?.name,
      price: item.price?.toFixed(2),
      quantity: parseFloat(item.qty || ''),
      index
    }))
  }

  // ******************* GOOGLE ANALYTICS API - ECOMMERCE *****************

  /** send cart data to GA */
  const updateCart = (
    action: 'add' | 'remove',
    value: number,
    items: CartItem[] //list of added / removed items
  ) => {
    try {
      // Measure adding a product to a shopping cart by using an 'add' actionFieldObject
      // and a list of productFieldObjects.
      if (action === 'add') {
        window.gtag?.('event', 'add_to_cart', {
          value,
          currency: Currency.SEK,
          items: itemFactory(items)
        })
      }

      if (action === 'remove') {
        window.gtag?.('event', 'remove_from_cart', {
          value,
          currency: Currency.SEK,
          items: itemFactory(items)
        })
      }
    } catch (e) {
      console.warn('GA - could not update Cart tracker')
    }
  }

  /** tell GA that the user checked out the cart contents */
  const viewCart = (items: CartItem[], value: number, currency: string) => {
    try {
      window.gtag?.('event', 'view_cart', {
        value,
        currency,
        items: itemFactory(items)
      })
    } catch (e) {
      console.warn('GA - could not update cart details')
    }
  }

  /** tell GA that the user went to checkout */
  const checkout = (products: CartItem[], value: number) => {
    //send cart content to sessionStorage for future use. Order information is stored for 15 min
    try {
      window.gtag?.('event', 'begin_checkout', {
        value,
        currency: Currency.SEK,
        items: itemFactory(products)
      })
    } catch (e) {
      console.warn('GA - could not update checkout details')
    }
  }
  const minorUnitsToFloatParser = (value: number | undefined) => {
    if (
      value === 0 ||
      (value as undefined) === 'undefined' ||
      value === null ||
      isNaN(value as number)
    )
      return '0.00'
    return Number((value as number) / 100).toFixed(2)
  }
  /** tell GA that the item has been purchased */
  const purchase = (order: GaPurchaseOrder, orderProducts: CheckoutProduct[]) => {
    try {
      const payload = {
        transaction_id: order.id,
        tax: minorUnitsToFloatParser(order.taxAmount),
        shipping: minorUnitsToFloatParser(order.shippingPrice),
        value: minorUnitsToFloatParser(order.totalAmount),
        currency: order.currency,
        items: orderProducts.map((p, index) => ({
          affiliation: 'Online Store',
          item_id: p.productId,
          item_name: p.name,
          item_brand: p.brand,
          price: p.unitPrice.toFixed(2),
          quantity: parseFloat(p.quantity.toString() || ''),
          index
        }))
      }
      window.gtag?.('event', 'purchase', payload)
    } catch (e) {
      console.warn('GA - could not update purchase details')
    }
  }

  // ******************* GOOGLE ANALYTICS API - SEARCH *****************

  /** provide user search term to GA */
  const search = (search_term: string) => {
    try {
      window.gtag?.('event', 'search', {
        search_term
      })
    } catch (e) {
      console.warn('GA - could not update search term')
    }
  }

  /** provide user search results to GA */
  const viewSearchResults = (search_term: string, items: GaSearchItem[]) => {
    try {
      window.gtag?.('event', 'view_search_results', {
        search_term,
        items
      })
    } catch (e) {
      console.warn('GA - could not update search term')
    }
  }

  // provide visited product detail page to GA
  const viewPdp = (page_path: string) => {
    try {
      window.gtag?.('event', 'view_pdp', {
        page_path
      })
    } catch (e) {
      console.warn('GA - could not update view_pdp')
    }
  }

  return {
    pageView,
    contactFormSubmit,
    selectContent,
    updateCart,
    viewCart,
    checkout,
    purchase,
    search,
    viewSearchResults,
    viewPdp
  }
}

export default useGoogleAnalytics
