import type { AssistantMessageFE, AssistantSizeStateFE } from 'ecosystem'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { assistantStorage } from 'shared-utils'

interface AssistantSliceState {
  history: AssistantMessageFE[] | null
  newMessages: AssistantMessageFE[] | null
  threadId?: string
  sessionUserId?: string | null
  isInit: boolean
  isSendingMessage: boolean
  initError: string | null
  errors: Record<AssistantMessageFE['id'], string>
  isOpen: boolean
  sizeState: AssistantSizeStateFE
}

const staticInitialState: AssistantSliceState = {
  history: null,
  newMessages: null,
  threadId: undefined,
  sessionUserId: undefined,
  isInit: false,
  isSendingMessage: false,
  initError: null,
  errors: {},
  isOpen: false,
  sizeState: 'MAX'
}

const assistantSlice = createSlice({
  name: 'assistant',
  initialState: () => {
    let sizeState = assistantStorage.getSizeState()
    sizeState =
      sizeState && ['MIN', 'MAX'].includes(sizeState)
        ? (sizeState as AssistantSizeStateFE)
        : staticInitialState.sizeState

    return {
      ...staticInitialState,
      threadId: assistantStorage.getThreadId() ?? undefined,
      sessionUserId: assistantStorage.getSessionUserId() ?? undefined,
      history: assistantStorage.getHistory(),
      sizeState
    } as AssistantSliceState
  },
  reducers: {
    setIsSendingMessage: (
      state: AssistantSliceState,
      { payload: isSendingMessage }: PayloadAction<AssistantSliceState['isSendingMessage']>
    ) => {
      state.isSendingMessage = isSendingMessage
    },
    setInitError: (
      state: AssistantSliceState,
      { payload: error }: PayloadAction<AssistantSliceState['initError']>
    ) => {
      state.initError = error
    },
    setIsInit: (
      state: AssistantSliceState,
      { payload: isInit }: PayloadAction<AssistantSliceState['isInit']>
    ) => {
      state.isInit = isInit
    },
    addMessage: (
      state: AssistantSliceState,
      { payload: message }: PayloadAction<AssistantMessageFE>
    ) => {
      if (!state.history) {
        state.history = [message]
      } else {
        state.history.push(message)
      }

      if (!state.newMessages) {
        state.newMessages = [message]
      } else {
        state.newMessages.push(message)
      }
    },
    addError: (
      state: AssistantSliceState,
      { payload: { id, error } }: PayloadAction<{ id: string; error: string }>
    ) => {
      state.errors[id] = error
    },
    setSessionUserId: (
      state: AssistantSliceState,
      { payload: sessionUserId }: PayloadAction<string | null>
    ) => {
      state.sessionUserId = sessionUserId
    },
    setThreadId: (state: AssistantSliceState, { payload: threadId }: PayloadAction<string>) => {
      state.threadId = threadId
    },
    setHistory: (
      state: AssistantSliceState,
      { payload: history }: PayloadAction<AssistantSliceState['history']>
    ) => {
      state.history = history
    },
    setIsOpen: (state: AssistantSliceState, { payload: isOpen }: PayloadAction<boolean>) => {
      state.isOpen = isOpen
    },
    setSizeState: (
      state: AssistantSliceState,
      { payload: sizeState }: PayloadAction<AssistantSizeStateFE>
    ) => {
      state.sizeState = sizeState
    },
    resetChat: (state: AssistantSliceState) => {
      const { history, newMessages, threadId, isInit, isSendingMessage, initError, errors } =
        staticInitialState

      state.history = history
      state.newMessages = newMessages
      state.threadId = threadId
      state.isInit = isInit
      state.isSendingMessage = isSendingMessage
      state.initError = initError
      state.errors = errors
    }
  }
})

export const {
  addMessage,
  setHistory,
  setThreadId,
  setSessionUserId,
  setIsInit,
  setInitError,
  setIsSendingMessage,
  addError,
  setSizeState,
  setIsOpen,
  resetChat
} = assistantSlice.actions

export const selectAssistant = (state: { assistant: AssistantSliceState }) => state.assistant
export const selectAssistantThreadId = (state: { assistant: AssistantSliceState }) =>
  state.assistant.threadId
export const selectAssistantSessionUserId = (state: { assistant: AssistantSliceState }) =>
  state.assistant.sessionUserId
export const selectAssistantHistory = (state: { assistant: AssistantSliceState }) =>
  state.assistant.history
export const selectAssistantNewMessages = (state: { assistant: AssistantSliceState }) =>
  state.assistant.newMessages
export const selectAssistantIsSendingMessage = (state: { assistant: AssistantSliceState }) =>
  state.assistant.isSendingMessage
export const selectAssistantIsInit = (state: { assistant: AssistantSliceState }) =>
  state.assistant.isInit
export const selectAssistantInitError = (state: { assistant: AssistantSliceState }) =>
  state.assistant.initError
export const selectAssistantErrors = (state: { assistant: AssistantSliceState }) =>
  state.assistant.errors
export const selectAssistantSizeState = (state: { assistant: AssistantSliceState }) =>
  state.assistant.sizeState
export const selectAssistantIsOpen = (state: { assistant: AssistantSliceState }) =>
  state.assistant.isOpen

export default assistantSlice.reducer
