import { configureStore } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import category from './slices/category/categorySlice'
import cart from './slices/cart/cartSlice'
import window from './slices/window/windowSlice'
import products from './slices/products/productsSlice'
import searchProducts from './slices/searchProducts/searchProductsSlice'
import assistant from './slices/assistant/assistantSlice'

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseSplitApi = createApi({
  reducerPath: 'base',
  baseQuery: fetchBaseQuery({
    baseUrl: `/api`
  }),
  tagTypes: ['Category'],
  endpoints: () => ({})
})

interface Extension<T, K extends string> {
  reducerPath: K
  reducer: T
}

export const storefrontBaseStoreCreator = <T, K extends string>(extensions: Extension<T, K>[]) => {
  const e = extensions.reduce((a, v) => ({ ...a, [v.reducerPath]: v.reducer }), {}) as Record<K, T>
  const reducer = {
    // reducers
    [baseSplitApi.reducerPath]: baseSplitApi.reducer,
    // slices
    category,
    cart,
    window,
    products,
    searchProducts,
    assistant,
    // slices extensions
    ...e
  }

  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseSplitApi.middleware)
  })
}
