import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
  ProductSearchFacets,
  ProductSearchResponseDTO,
  SearchBrandFacetDTO,
  SearchCategoryFacetDTO,
  SearchOptionFacetDTO,
  SearchOptionValueFacetDTO,
  Nullable
} from 'ecosystem'
import { isEmpty } from 'shared-utils'
import type { SearchProductsState, TPriceRangeFilter, TProductSearchSelectedFilters } from './types'

const initialState: SearchProductsState = {
  defaultFilters: {
    brands: [],
    categories: [],
    options: [],
    prices: [],
    minPrice: null,
    maxPrice: null
  },
  availableFilters: {
    brands: [],
    categories: [],
    options: [],
    prices: [],
    minPrice: null,
    maxPrice: null
  },
  selectedFilters: {
    brands: [],
    categories: [],
    options: {},
    minPrice: null,
    maxPrice: null
  },
  appliedSelectedFilters: {
    brands: [],
    categories: [],
    options: {},
    minPrice: null,
    maxPrice: null
  },
  page: 1,
  initStatus: 'PENDING',

  content: null,
  totalPages: 0,
  totalElements: 0
}

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    updateOptionFilterItemSelected: (
      state,
      {
        payload
      }: PayloadAction<{
        id: SearchOptionValueFacetDTO['id']
        groupName: SearchOptionFacetDTO['name']
        isSelected: boolean
      }>
    ) => {
      const { selectedFilters } = state
      const group = selectedFilters.options[payload.groupName] as string[] | undefined

      if (payload.isSelected) {
        if (group) {
          group.push(payload.id)
        } else {
          state.selectedFilters.options = {
            ...state.selectedFilters.options,
            [payload.groupName]: [payload.id]
          }
        }
      } else if (group) {
        const index = group.findIndex((id) => id === payload.id)

        if (index !== -1) {
          group.splice(index, 1)

          if (!group.length) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- we need to delete it
            delete selectedFilters.options[payload.groupName]
          }
        }
      }
      return state
    },

    updateBrandFilterItemSelected: (
      state,
      {
        payload
      }: PayloadAction<{
        id: SearchBrandFacetDTO['id']
        isSelected: boolean
      }>
    ) => {
      const { selectedFilters } = state

      if (payload.isSelected) {
        selectedFilters.brands.push(payload.id)
      } else {
        const index = selectedFilters.brands.findIndex((id) => id === payload.id)

        if (index !== -1) {
          selectedFilters.brands.splice(index, 1)
        }
      }
    },

    updateCategoryFilterItemSelected: (
      state,
      {
        payload
      }: PayloadAction<{
        id: SearchCategoryFacetDTO['id']
        isSelected: boolean
      }>
    ) => {
      const { selectedFilters } = state

      if (payload.isSelected) {
        selectedFilters.categories.push(payload.id)
      } else {
        const index = selectedFilters.categories.findIndex((id) => id === payload.id)

        if (index !== -1) {
          selectedFilters.categories.splice(index, 1)
        }
      }
    },

    updateAvailableFilters: (
      state,
      { payload: facets }: PayloadAction<Nullable<ProductSearchFacets>>
    ) => {
      const { selectedFilters, availableFilters } = state
      const newAvailableFilters: Required<ProductSearchFacets> = {
        brands: selectedFilters.brands.length ? availableFilters.brands : facets.brands || [],
        categories: selectedFilters.categories.length
          ? availableFilters.categories
          : facets.categories || [],
        options: facets.options || [],
        prices: facets.prices || [],
        minPrice: Number.isInteger(selectedFilters.minPrice)
          ? availableFilters.minPrice
          : facets.minPrice,
        maxPrice: Number.isInteger(selectedFilters.maxPrice)
          ? availableFilters.maxPrice
          : facets.maxPrice
      }

      if (!isEmpty(selectedFilters.options)) {
        newAvailableFilters.options = availableFilters.options.reduce<SearchOptionFacetDTO[]>(
          (acc, item) => {
            if (selectedFilters.options[item.name]) {
              acc.push(item)
            } else {
              const option = facets.options?.find(({ name }) => item.name === name)

              if (option) {
                acc.push(option)
              }
            }

            return acc
          },
          []
        )
      }

      state.availableFilters = newAvailableFilters
    },

    setDefaultFilters: (
      state,
      { payload: facets }: PayloadAction<Nullable<ProductSearchFacets>>
    ) => {
      state.defaultFilters = {
        brands: facets.brands || [],
        categories: facets.categories || [],
        options: facets.options || [],
        prices: facets.prices || [],
        minPrice: facets.minPrice,
        maxPrice: facets.maxPrice
      }
    },

    setSelectedPriceRange: (state, { payload: priceRange }: PayloadAction<TPriceRangeFilter>) => {
      const { availableFilters } = state
      const [minPrice, maxPrice] = priceRange

      if (availableFilters.minPrice === minPrice && availableFilters.maxPrice === maxPrice) {
        state.selectedFilters.minPrice = null
        state.selectedFilters.maxPrice = null
      } else {
        state.selectedFilters.minPrice = minPrice
        state.selectedFilters.maxPrice = maxPrice
      }
    },

    updateSelectedFiltersFromQuery: (
      state,
      { payload: newSelectedFilters }: PayloadAction<Partial<TProductSearchSelectedFilters>>
    ) => {
      const { brands = [], options = {}, categories = [], ...rest } = newSelectedFilters

      state.selectedFilters = {
        brands,
        categories,
        options,
        ...rest
      }
      state.appliedSelectedFilters = {
        brands,
        categories,
        options,
        ...rest
      }
    },

    setAppliedSelectedFilters: (
      state,
      { payload: newSelectedFilters }: PayloadAction<Partial<TProductSearchSelectedFilters>>
    ) => {
      const { brands = [], options = {}, categories = [], ...rest } = newSelectedFilters

      state.appliedSelectedFilters = {
        brands,
        categories,
        options,
        ...rest
      }
    },

    resetSearchProducts: () => {
      return initialState
    },

    setInitStatus: (
      state,
      { payload: initStatus }: PayloadAction<SearchProductsState['initStatus']>
    ) => {
      state.initStatus = initStatus
    },

    initSearchProducts: (state, { payload }: PayloadAction<ProductSearchResponseDTO>) => {
      if (!isEmpty(payload)) {
        state.content = payload.products
        state.totalPages = payload.totalPages
        state.totalElements = payload.totalElements
        state.defaultFilters = {
          brands: payload.facets.brands || [],
          categories: payload.facets.categories || [],
          options: payload.facets.options || [],
          prices: payload.facets.prices || [],
          minPrice: payload.facets.minPrice,
          maxPrice: payload.facets.maxPrice
        }
        state.availableFilters = {
          brands: payload.facets.brands || [],
          categories: payload.facets.categories || [],
          options: payload.facets.options || [],
          prices: payload.facets.prices || [],
          minPrice: payload.facets.minPrice,
          maxPrice: payload.facets.maxPrice
        }
      }
    },

    setProducts: (state, { payload }: PayloadAction<ProductSearchResponseDTO>) => {
      if (!isEmpty(payload)) {
        state.content = payload.products
        state.totalPages = payload.totalPages
        state.totalElements = payload.totalElements
      }
    },

    addProducts: (
      state,
      { payload: products }: PayloadAction<ProductSearchResponseDTO['products']>
    ) => {
      if (products.length) {
        state.content = (state.content || []).concat(products)
      }
    },

    setNextPage: (state) => {
      state.page += 1
    },

    resetPage: (state) => {
      state.page = initialState.page
    },

    resetSelectedFilters: (state) => {
      state.selectedFilters = initialState.selectedFilters
      state.appliedSelectedFilters = initialState.appliedSelectedFilters
      state.availableFilters = state.defaultFilters

      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const {
  setInitStatus,
  updateOptionFilterItemSelected,
  updateCategoryFilterItemSelected,
  updateBrandFilterItemSelected,
  resetSearchProducts,
  initSearchProducts,
  setProducts,
  setNextPage,
  resetPage,
  setDefaultFilters,
  updateSelectedFiltersFromQuery,
  setAppliedSelectedFilters,
  addProducts,
  updateAvailableFilters,
  setSelectedPriceRange,
  resetSelectedFilters
} = slice.actions

interface RootState {
  searchProducts: SearchProductsState
}
// available selectors - add more as needed
export const selectSearchProducts = (state: RootState) => state.searchProducts
export const selectSearchProductsAvailableFilters = (state: RootState) =>
  selectSearchProducts(state).availableFilters
export const selectSearchProductsSelectedFilters = (state: RootState) =>
  selectSearchProducts(state).selectedFilters
export const selectSearchProductsAppliedSelectedFilters = (state: RootState) =>
  selectSearchProducts(state).appliedSelectedFilters
export const selectSearchProductsProducts = (state: RootState) => state.searchProducts.content
export const selectSearchProductsInitStatus = (state: RootState) =>
  selectSearchProducts(state).initStatus
export const selectSearchProductsInit = (state: RootState) =>
  selectSearchProductsInitStatus(state) === 'SUCCESS'
export const selectSearchProductsTotalElements = (state: RootState) =>
  state.searchProducts.totalElements
export const selectSearchProductsPage = (state: RootState) => selectSearchProducts(state).page

export default slice.reducer
