'use client'
import type { FC } from 'react'
import { Provider } from 'react-redux'
import type { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'

interface IReduxToolkitProviderProps {
  children: any
  store: ToolkitStore
}

export const ReduxToolkitProvider: FC<IReduxToolkitProviderProps> = ({ children, store }) => {
  return <Provider {...{ store }}>{children}</Provider>
}
