import type { PayloadAction, Slice } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { GetProductEndpointPayload } from 'api'
import { DEFAULT_PRODUCT_FILTER_RANGE } from 'api'
import { isEmpty, optionHelpers } from 'shared-utils'
import type {
  GetProductsRes,
  OldStoreProductFilterDTO,
  ProductLightDTO,
  SortingOption,
  UIVariantOption
} from 'ecosystem'
import type { ProductsState } from './types'
import { PAGE_SIZE } from './constants'

interface CreateProductsSliceParams {
  initialState?: Partial<ProductsState>
}

export const createProductsSlice = (params?: CreateProductsSliceParams): Slice<ProductsState> => {
  const initialState: ProductsState = {
    content: null,
    size: PAGE_SIZE,
    filter: null,
    availableFilters: [],
    brandFilterOptions: [],
    selectedFilterCheckboxes: [],
    selectedFilterNumericValues: [],
    selectedFilterBrandIds: [],
    priceRangeFilter: DEFAULT_PRODUCT_FILTER_RANGE,
    totalPages: 0,
    totalElements: 0,
    selectedProductVariants: [],
    productsListId: null,
    sortOrder: 'ASC',
    sortBy: '',
    sortLabel: '',
    selectedOptions: [],
    ...params?.initialState
  }

  return createSlice({
    name: 'products',
    initialState,
    reducers: {
      setSelectedProducts: (state, { payload }: PayloadAction<GetProductsRes>) => {
        if (!isEmpty(payload)) {
          state.content = payload.content
          state.totalPages = payload.totalPages
          state.totalElements = payload.totalElements
        }
        return state
      },

      setSelectedProductListId: (state, { payload }: PayloadAction<string>) => {
        state.productsListId = payload
        return state
      },

      setSelectedSize: (state, { payload }: PayloadAction<number>) => {
        state.size = payload
        return state
      },

      setSelectedProductFilter: (state, { payload }: PayloadAction<GetProductEndpointPayload>) => {
        state.filter = payload
        return state
      },

      setSelectedAvailableFilters: (
        state,
        { payload }: PayloadAction<OldStoreProductFilterDTO>
      ) => {
        state.availableFilters = payload.options || []
        state.brandFilterOptions = payload.brands
        return state
      },

      setSelectedPriceRange: (state, { payload }: PayloadAction<[number, number]>) => {
        state.priceRangeFilter = payload
        return state
      },

      setSelectedSorting: (state, { payload }: PayloadAction<SortingOption>) => {
        state.sortOrder = payload.sortOrder || state.sortOrder
        state.sortBy = payload.sortBy || state.sortBy
        state.sortLabel = payload.sortLabel || state.sortLabel
        return state
      },

      setSelectedFilterCheckboxes: (
        state,
        { payload }: PayloadAction<{ checkboxName: string; isChecked: boolean; checkboxId: string }>
      ) => {
        const checkboxName = payload.checkboxName
        const isChecked = payload.isChecked
        const checkboxId = payload.checkboxId

        const current = [...state.selectedFilterCheckboxes]
        const f = current.find((e) => e.id === checkboxId)

        if (f) {
          if (f.values.includes(checkboxName)) {
            // the value exists and the filter too
            if (!isChecked) {
              f.values = f.values.filter((e) => e !== checkboxName)
            }
          } else {
            // the filter exists but the value doesn't exist inside
            f.values = [...f.values, checkboxName]
          }
        } else {
          // the filter doesn't exist at all
          current.push({
            id: checkboxId,
            values: [checkboxName]
          })
        }

        state.selectedFilterCheckboxes = [...current].filter((e) => e.values.length > 0)

        return state
      },

      setSelectedFilterNumericValues: (
        state,
        { payload }: PayloadAction<{ id: string; range: [number, number] }>
      ) => {
        const numericValueId = payload.id
        const range = payload.range

        const current = [...state.selectedFilterNumericValues]
        const existingNumericValue = current.find((e) => e.id === numericValueId)

        if (existingNumericValue) {
          existingNumericValue.range = range
        } else {
          current.push(payload)
        }

        state.selectedFilterNumericValues = [...current]

        return state
      },

      removeSelectedFilterNumericValue: (state, { payload }: PayloadAction<string>) => {
        const current = [...state.selectedFilterNumericValues]
        state.selectedFilterNumericValues = current.filter((item) => item.id !== payload)
        return state
      },

      setSelectedFilterBrandIds: (state, { payload }: PayloadAction<{ brandIds: string[] }>) => {
        state.selectedFilterBrandIds = payload.brandIds
        return state
      },

      toggleFilterBrand: (state, { payload }: PayloadAction<{ brandId: string }>) => {
        const brandId = payload.brandId
        let selected = [...state.selectedFilterBrandIds]

        if (selected.includes(brandId)) selected = selected.filter((id) => id !== brandId)
        else selected.push(brandId)

        state.selectedFilterBrandIds = selected
        return state
      },

      setSelectedProductVariants: (state, { payload }: PayloadAction<ProductLightDTO[]>) => {
        state.selectedProductVariants = payload
        return state
      },

      resetFilters: (state, _payload: PayloadAction) => {
        state.selectedFilterCheckboxes = []
        state.selectedFilterNumericValues = []
        state.selectedFilterBrandIds = []
        state.priceRangeFilter = initialState.priceRangeFilter
        state.sortOrder = initialState.sortOrder
        state.sortBy = initialState.sortBy
        state.sortLabel = initialState.sortLabel

        return state
      },

      setSelectedOptions: (
        state,
        { payload: { option } }: PayloadAction<{ option: UIVariantOption }>
      ) => {
        const { optionFinder, groupFinder, itemFilter, groupFilter } = optionHelpers(option)
        const isOptionSelected = state.selectedOptions.find(optionFinder)
        const isGroupSelected = state.selectedOptions.find(groupFinder)

        if (isOptionSelected) {
          state.selectedOptions = state.selectedOptions.filter(itemFilter)
          return state
        }

        if (isGroupSelected) {
          state.selectedOptions = [...state.selectedOptions.filter(groupFilter), option]
          return state
        }

        state.selectedOptions = [...state.selectedOptions, option]

        return state
      }
    }
  })
}

const slice = createProductsSlice()

// available actions - add more as needed, first they will have to be declared above
export const {
  setSelectedProducts,
  setSelectedProductListId,
  setSelectedSize,
  setSelectedAvailableFilters,
  setSelectedProductFilter,
  setSelectedFilterCheckboxes,
  setSelectedFilterNumericValues,
  removeSelectedFilterNumericValue,
  setSelectedFilterBrandIds,
  toggleFilterBrand,
  setSelectedPriceRange,
  setSelectedProductVariants,
  resetFilters,
  setSelectedSorting,
  setSelectedOptions
} = slice.actions

// available selectors - add more as needed
export const selectedProductState = (state: { products: ProductsState }) => state.products
export const selectedListId = (state: { products: ProductsState }) => state.products.productsListId
export const selectedAvailableFilters = (state: { products: ProductsState }) =>
  state.products.availableFilters
export const selectedBrandFilterOptions = (state: { products: ProductsState }) =>
  state.products.brandFilterOptions
export const selectedPriceRange = (state: { products: ProductsState }) =>
  state.products.priceRangeFilter
export const selectedFilterCheckboxes = (state: { products: ProductsState }) =>
  state.products.selectedFilterCheckboxes
export const selectedFilterNumericValues = (state: { products: ProductsState }) =>
  state.products.selectedFilterNumericValues
export const selectedFilterBrandIds = (state: { products: ProductsState }) =>
  state.products.selectedFilterBrandIds
export const selectedProducts = (state: { products: ProductsState }) => state.products.content
export const selectedSize = (state: { products: ProductsState }) => state.products.size
export const selectedFilter = (state: { products: ProductsState }) => state.products.filter
export const selectedTotalElements = (state: { products: ProductsState }) =>
  state.products.totalElements
export const selectedProductVariants = (state: { products: ProductsState }) =>
  state.products.selectedProductVariants
export const selectedSorting = (state: { products: ProductsState }) => ({
  sortOrder: state.products.sortOrder,
  sortBy: state.products.sortBy
})

export const selectedOptionsState = (state: { products: ProductsState }) =>
  state.products.selectedOptions

// exports slice reducer
export default slice.reducer
