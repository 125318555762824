import type { ProductLightDTO } from 'ecosystem'
import { baseSplitApi } from '../../store'
import { setSelectedProductVariants } from '../../slices/products'

export interface GetVariantEndpointPayload {
  variantId: string
}

export const getVariantEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getVariant: builder.mutation<ProductLightDTO[], GetVariantEndpointPayload>({
      query: ({ variantId }) => ({
        url: `products/get-variant/${variantId}`,
        method: 'GET'
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          //  on success it dispatches the data to the state
          dispatch(setSelectedProductVariants(data))
        } catch {
          dispatch(setSelectedProductVariants([]))
        }
      }
    })
  })
})

// Export hooks for usage in functional components
export const {
  useGetVariantMutation,
  util: { getRunningOperationPromises: getRunningOperationPromisesGetProductsEndpoint }
} = getVariantEndpoint

// export endpoints for use in SSR
export const { getVariant } = getVariantEndpoint.endpoints
